import React from 'react'

import { Container, ContainerCard, Card } from './styles'

import Alvo from '../../../assets/alvo.png'
import Valores from '../../../assets/valores.png'
import Visao from '../../../assets/visao.png'



function BlocoTrês() {


  return (
    <Container>
      <div className="widthFixed">

        <ContainerCard>
          <Card className="CardMissao">
            <div className='mobileOG'>

              <p className='title'>
                MISSÃO
          </p>
              <p className='blocotext'>
                Proporcionar ao mercado soluções inteligentes, com produtos e serviço de qualidade diferenciada de acordo com as normas vigentes
          </p>
            </div>

            <div className='BoxImageAlvo'>
              <img src={Alvo} alt="" />
            </div>
          </Card>

          <Card className="CardVisao">
            <div className='mobileOG'>

              <p className='title'>
                VISÃO
          </p>
              <p className='blocotext'>
                Constância no comprometimento e credibilidade de construir de acordo com o projeto

          </p>
            </div>

            <div className='BoxImage'>
              <img src={Visao} alt="" />
            </div>
          </Card>

          <Card className="CardValores">
            <div className='mobileOG'>

              <p className='title'>
                VALORES
          </p>
              <p className='blocotext'>
                Ser referência com projetos inovadores, com ética profissional, respeito e confiança aos nossos clientes .

          </p>
            </div>

            <div className='BoxImage'>
              <img src={Valores} alt="" />
            </div>
          </Card>

        </ContainerCard>
      </div>

    </Container>
  );
}

export default BlocoTrês;
