import React from 'react'

import { Container, StyledTabs, useStyles } from './styles'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'

import { Paper, Tab, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import ModeloL from './Models/ModeloL'
import ModeloU from './Models/ModeloU'
import ModeloReta from './Models/ModeloReta'
import ModeloCaracol from './Models/ModeloCaracol'
import ModeloSemiCa from './Models/ModeloSemicaracol';
import ModeloEspeciais from './Models/ModelosEspeciais';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={5}>
                    <Typography>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

export default function Produtos() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container>
            <Header />
            <Container>
                <Paper className={classes.root}>
                    <StyledTabs
                        className={classes.Tabs}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="inherit"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        <Tab className={classes.Tab} label="Modelo L" />
                        <Tab className={classes.Tab} label="Modelo U" />
                        <Tab className={classes.Tab} label="Modelo Reta" />
                        <Tab className={classes.Tab} label="Modelo Caracol" />
                        <Tab className={classes.Tab} label="Modelo Semicaracol" />
                        <Tab className={classes.Tab} label="Modelos Especiais" />

                    </StyledTabs>
                    <TabPanel value={value} index={0}>
                        <ModeloL />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ModeloU />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ModeloReta />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ModeloCaracol />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <ModeloSemiCa />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <ModeloEspeciais />
                    </TabPanel>
                </Paper>
            </Container>
            <Footer />
        </Container>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};