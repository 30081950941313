import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const Content = styled.div`
  @media (min-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  @media (min-width: 1191px) {
    flex-direction: row;
  }
`;

export const Map = styled.div`
  background: #3f4140;
  height: 300px;
  width: 100%;

  iframe {
    opacity: 0.4;
  }

  @media (min-width: 1189px) {
    height: 100vmax;
    width: 100%;
  }
`;

export const Form = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 2em;
  }

  .information {
    display: flex;
    align-items: center;
    justify-content: initial;
    width: 100%;
    flex-direction: column;

    p {
      font-family: poppins;
    }
  }

  .endereco-wrapper {
    display: flex;

    flex-direction: column;
    justify-content: initial;

    strong {
      text-align: center;
      padding-top: 40px;
    }
  }
  .mark-addres {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .others-mark {
    width: 100%;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
  }

  .cont-number,
  .cont-hour {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: poppins;
  }

  .cont-hour p {
    padding-left: 20px;
  }
  .cont-number p {
    padding-left: 20px;
  }

  @media (min-width: 600px) {
  }

  @media (min-width: 851px) {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const FormContact = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  box-sizing: border-box;

  .boxText {
    margin: 10px;
    margin-bottom: 16px;
    width: 100%;
    max-width: 580px;
  }

  #filled-multiline-static {
    height: 250px !important;
  }

  @media (max-width: 1110px) {
    #filled-multiline-static {
      height: 180px !important;
    }
  }

  @media (max-width: 685px) {
    #filled-multiline-static {
      height: 150px !important;
    }
  }

  .container-textfield {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 849px) {
    margin: 0px;
  }
`;

export const ButtonSend = styled.button`
  width: 100%;
  max-width: 327px;
  height: 58px;
  border: none;
  border-radius: 25px;
  background: #616862;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 45px;
  color: #ffffff;
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
`;

export const ContentMobile = styled.div`
  width: 100%;

  max-width: 280px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    text-decoration: none;
    font-size: 0.9em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .wrapper-mobile {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    box-sizing: border-box;
  }

  .email-box {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 280px;
    background: rgba(94, 103, 105, 0.5);

    margin-top: 25px;

    font-size: 1.2em;
    font-family: Poppins;
    font-weight: 500;

    padding-top: 20px;
    padding-top: 20px;
    padding-right: 5px;
    padding-left: 5px;

    box-sizing: border-box;
  }

  .address {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    text-align: center;

    width: 100%;
    max-width: 280px;

    background: #ebe9e9;

    font-size: 1.2em;
    font-family: Poppins;
    font-weight: 500;
    padding-top: 20px;
    padding-right: 5px;
    padding-left: 5px;
    box-sizing: border-box;
    text-align: center;
  }

  .number-contact {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    text-align: center;
    padding-top: 20px;
    padding-right: 5px;
    padding-left: 5px;
    box-sizing: border-box;

    width: 100%;
    max-width: 280px;

    background: #ebe9e9;

    font-size: 1.2em;
    font-family: Poppins;
    font-weight: 500;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .hour-contact {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    text-align: center;
    padding-top: 20px;
    padding-right: 5px;
    padding-left: 5px;
    box-sizing: border-box;

    width: 100%;
    max-width: 280px;

    background: #ebe9e9;

    font-size: 1.2em;
    font-family: Poppins;
    font-weight: 500;
  }

  .hour-contact strong {
    margin: 4px 0 0;
  }

  .hour-contact p {
    margin: 6px 0 12px;
  }
`;

export const ContainerNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 16px;

  a {
    margin-bottom: 0;
    color: #000000;
    font-weight: bold;
  }
  p {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 17px;
  }
`;
