import styled from 'styled-components'

import Escadas from '../../../assets/imageComercial.png'

export const Container = styled.div`
    width: 100%;
    height: 1024px;
    background: #DADBC9;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const Image = styled.div`
    width: 100%;
    height: 730px;
    position: relative;

    background-image: url(${Escadas});
    background-size: cover;
    opacity: 0.3;
`

export const Frase = styled.h1`
    font-family: Poppins;
    font-size: 2.4em;
    font-weight: 500;
    text-align: center;

    width: 100%;
    max-width: 1024px;
    position: absolute;

    color: #3a3a3a;

    transition: 1s;

    @media(min-width: 600px) {
        font-size: 6em;

    }
`

export const ButtonQuemSomos = styled.button`
    font-family: Poppins;
    font-size: 2em;
    font-weight: 700;
    text-align: center;

    color: #DE6A1E;

    width: 100%;
    max-width: 721px;
    height: 111px;
    background: #F7D47A;
    
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);
    position: absolute;
    top: 720px;

    transition: 1s;

    @media(min-width: 600px) {
        font-size: 4em;
        top: 820px;

    }
`