import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';

import { Tabs, Tab } from '@material-ui/core'


export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;
`

export const StyledTabs = styled(Tabs)`
    .MuiTabs-flexContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    Tabs: {
        borderBottom: "1px solid #c4c4c4 ",
        backgroundColor: "#c4c4c4",
        opacity: 1,
    },
    Tab: {
        fontFamily: "Poppins",
        fontWeight: theme.typography.fontWeightBold,
        '&:disabled': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        fontSize: 14,
        ['@media (max-width:987px)']: {
            fontSize: 12,
        },
    }
}));