import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .container {
    width: 90%;
    padding: 20px;
    margin: 100px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .box {
    width: 100%;
    max-width: 320px;
    margin: 0 20px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, .1);

    transition: 1s;
  }

  .box img {
    display: block;
    width: 100%;
    border-radius: 5px;
  }

  .box:hover {
    transform: scale(1.3);
    z-index: 2;
  }
`

export const Card = styled.div`
  display: flex;
  
  img {
    width: 100%;
    padding: 20px;
  }

  @media (min-width: 600px) {
    
  } 
`