import React from 'react'

import { Container, Card } from './styles'

import RetaComViga from '../../assets/Reta/retaComViga.jpeg'
import RetaFixada from '../../assets/Reta/retaFixada.jpeg'
import RetaFixadaTwo from '../../assets/Reta/retaFixadaTwo.jpeg'

import Carousel, { autoplayPlugin, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


export default function ModeloReta() {

  return (
    <Container>
      <Carousel plugins={[
        'arrows',
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 2
          }
        },
      ]}
        breakpoints={{
          900: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2
                }
              },
            ]
          }
        }}>
        <Card>
          <img src={RetaComViga} alt="" />
        </Card>
        <Card>
          <img src={RetaFixada} alt="" />
        </Card>
        <Card>
          <img src={RetaFixadaTwo} alt="" />
        </Card>

      </Carousel>
    </Container>
  )
}

