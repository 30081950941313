import React from 'react'

import { Container, Card } from './styles'


import CaracolFive from '../../assets/Caracol/caracolFive.jpeg'
import CaracolOne from '../../assets/Caracol/caracolOne.jpeg'

import CaracolThree from '../../assets/Caracol/caracolThree.jpeg'

import Carousel, { autoplayPlugin, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


export default function ModeloCaracol() {

  return (
    <Container>
      <Carousel plugins={[
        'arrows',
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 2
          }
        },
      ]}
        breakpoints={{
          900: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2
                }
              },
            ]
          }
        }}>
        <Card>
          <img src={CaracolFive} alt="" />
        </Card>
        <Card>
          <img src={CaracolOne} alt="" />
        </Card>
        <Card>
          <img src={CaracolThree} alt="" />
        </Card>

      </Carousel>
    </Container>
  )
}

