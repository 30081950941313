import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;

    background-color: rgba(74, 64, 98, 0.4) ;


    display: flex;
    align-items: center;
    justify-content: center;

    .widthFixed {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      position: relative;
    }

    @media(min-width: 600px) {

    }

    @media(min-width: 1024px) {
        height: 700px;

    }


`

export const ContainerCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    .CardMissao {
        height: 610px !important;
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }

    .CardValores {
        height: 610px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .CardVisao {
        height: 610px !important;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .mobileOG {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        order:2;

        font-size: 1.4em;
        font-family: Poppins;
        padding:10px;

    }

    .title {
        font-size: 1.4em;
        color: #3A3A3A;
        font-weight: 700;

    }

    .BoxImage {
        order:1;
        padding:10px;
    }
    
    .BoxImageAlvo {
        padding-left:40px;

    }

    @media(min-width: 1024px) {
        flex-direction: row;

    }
`

export const Card = styled.div`
       
      img {
          width: 170px;
      }
      margin: 0px;
      margin-top: 10px;

    @media(min-width: 600px) {

        width: 100%;
        max-width: 390px;


        border-radius: 25px;
        box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                    inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                    5px 5px 5px rgba(0, 0, 0, 0.5),
                    -5px -5px 5px rgba(255, 255, 255, 0.05);
        background: #ABA186;
        margin: 20px;
        padding: 25px;
        box-sizing: border-box;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        flex-direction: column;

        .title {
            font-family: Poppins;
            font-size: 1.8em;
            text-align: center;
            color: #3A3A3A;

            
        }

        .blocotext {
            font-family: Poppins;
            font-weight: 400;
            font-size: 0.8em;
            text-align: center;

        }

        .mobileOG {
            order:1;

        }

        .BoxImageAlvo {
            order:2;
            padding-left:40px;
        }

        img {
            width: 200px;
        }

       

    }
`