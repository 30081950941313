import styled from "styled-components";
import Payment from "../../../assets/banner-payment.png";

export const Container = styled.div`
  width: 100%;
  height: 507px;
  margin-top: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-image: url(${Payment});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  gap: 20px;

  h1 {
    font-size: 48px;
    color: #d9d9d9;
    text-align: center;
  }

  span {
    font-size: 38px;
    color: #d9d9d9;
    max-width: 1113px;
    text-align: center;
    transition: 0.1s;
  }

  img {
    width: 100%;
    max-width: 1113px;
    transition: 0.1s;
  }
`;

export const Frase = styled.h1`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.8em;
  text-align: center;
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginT};

  @media (min-width: 600px) {
    font-size: 2em;
  }
`;
