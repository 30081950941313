import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

`

export const Card = styled.div`
  display: flex;
  height: 100%;
  
  img {
    width: 100%;
    padding: 20px;
  }

  @media (min-width: 600px) {
    
  } 
`