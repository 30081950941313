import styled from 'styled-components'

import Logo from '../../assets/logo_fundoContact.png'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;

    background-image: url(${Logo});
    background-size: 80%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;

    .Content {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;

        padding-left: 40px;
        padding-right: 40px;

    }

    .box-img img {
       display: none;


    }

    @media(min-width: 600px) {
       .box-img img {
        display: flex;

        width: 100%;
        max-width: 300px;

        transition: 1s;
    }
}

    @media(min-width: 851px) {
        .box-img img {
            width: 100%;
            max-width: 500px;

            transition: 1s;

        }
    }
`

export const Titulo = styled.h1`
    font-family: Poppins;
    font-weight: 500;
    font-size: 4em;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: #3a3a3a;
    width: 100%;
    max-width: 646px;
    margin-top:105px;

    .Border {
        width: 100%;
        max-width: 646px;
        border: 1px solid #000000;
        margin-top: 11px;
    }
`

export const BoxText = styled.p`
    width: 100%;
    max-width: 1263px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 1.4em;
    margin-top: ${props => props.marginT};


`