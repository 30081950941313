import styled from 'styled-components'

import Background from '../../../assets/background.jpeg'
import BackgroundMobile from '../../../assets/background-mobile.png'

import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { HiMenu } from "react-icons/hi"


export const Container = styled.div`
    width: 100%;
    height: 356px;
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center; 
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .efeitoSombra {
        width: 100%;
        height: 356px;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        order: 1;
    }

    .widthFixed {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      position: relative;
    }

    .menu button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }

    @media(min-width: 375px) {
      height: 426px;

      transition: 1s;

      .efeitoSombra {
        height: 426px;
        transition: 1s;

      }

    }

    @media(min-width: 500px) {
      background-image: url(${Background});
      height: 100vh;
      background-size: cover;


      transition: 1s;

      .efeitoSombra {
        height: 100vh;
        transition: 1s;

      }

    }

    @media(min-width: 600px) {
        .menu {
            display: none;
        }
    }
`

export const ButtonOrcamento = styled.button`
  width: 100%;
  max-width: 275px;
  height: 75px;
  background: #E1E1E1;
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  right: 4%;
  bottom: 25px;
  outline: none;
  border: none;

  font-size: 1em;
  font-family: Poppins;
  color: #00A859;
  font-weight: 500;

  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 2.2em;
    height: 2.2em;
  }

  transition: 0.5s;

  :hover {
    transform: scale(1.1);
    z-index: 2;
  }

@media(min-width: 851px) {
  width: 100%;
  max-width: 475px;
  height: 75px;
  background: #E1E1E1;
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  right: 4%;
  bottom: 25px;
  outline: none;
  border: none;

  font-size: 28px;
  font-family: Poppins;
  color: #00A859;
  font-weight: 500;

  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 50px;
    height: 50px;
  }

  transition: 0.5s;

  :hover {
    transform: scale(1.1);
    z-index: 2;
  }
}
`

export const ButtonAnimated = styled.button`
  width: 100%;
  max-width: 152px;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  
  :hover {
    background-color: rgba(249, 249, 249, 0.1);
  }

  span {
    display: block;
    position: absolute;
    background: #B79E7D;
  }

  span:nth-child(1) {
    left: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s;
  }

  :hover span:nth-child(1) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.5s;
  }

  span:nth-child(2) {
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 152px;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s;
  }

  :hover span:nth-child(2) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s;
  }

  span:nth-child(3) {
    right: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s;
  }

  :hover span:nth-child(3) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.5s;
  }

  span:nth-child(4) {
    left: 0;
    top: 0;
    width: 100%;
    max-width: 152px;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s;
  }

  :hover span:nth-child(4) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s;
  }

  .textLink {
    color: #E1E1E1;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
    text-decoration:none;
  }
  
`

export const Header = styled.div`
  display: none;

  @media(min-width: 600px) {
    width: 100%;
    max-width: 600px;

    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 32px;
    left: 25px;
  }

  @media(min-width: 851px) {
    width: 100%;
    max-width: 600px;

    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 32px;
    left: 25px;
  }
`

/*
@media(min-width: 600px) {

}

@media(min-width: 851px) {

}
 */


export const MenuIcon = styled(HiMenu)`
    color: #ffffff;
`

export const ListItemStyled = styled(ListItemText)`
.buttonContact {
        font-family: Poppins;
        text-align: center;
    }
`

export const ListIconStyled = styled(ListItemIcon)`

    img {
        width: 42px;
    }
`