import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Homepage from './pages/Homepage'
import Contato from './pages/Contato'
import Produtos from './pages/Produtos'
import QuemSomos from './pages/QuemSomos'

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Homepage} />
                <Route path="/contato" component={Contato} />
                <Route path="/produtos" component={Produtos} />
                <Route path="/quemsomos" component={QuemSomos} />
            </Switch>
        </BrowserRouter>
    )
}