import React from "react";

import { Link } from "react-router-dom";

import { Container, ButtonAnimated, ButtonOrcamento, Header, MenuIcon, ListItemStyled, ListIconStyled } from "./styles";

import WhatsAppIcon from "../../../assets/whatsappIcon.png";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ContactIcon from "../../../assets/telephone_page-contact.png";
import QuemSomosIcon from "../../../assets/team.png";
import ProdutoIcon from "../../../assets/stair.png";
import HomeIcon from "../../../assets/house.png";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

function BlocoUm() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        <ListItem button>
          <ListIconStyled>
            {" "}
            <img src={HomeIcon} alt="" />
          </ListIconStyled>
          <ListItemStyled>
            <div className="buttonContact">
              <Link style={{ textDecoration: "none", color: "#000000" }} to="/">
                {" "}
                Inicio{" "}
              </Link>
            </div>
          </ListItemStyled>
        </ListItem>
        <ListItem button>
          <ListIconStyled>
            {" "}
            <img src={ContactIcon} alt="" />
          </ListIconStyled>
          <ListItemStyled>
            <div className="buttonContact">
              <Link style={{ textDecoration: "none", color: "#000000" }} to="/contato">
                {" "}
                Fale conosco{" "}
              </Link>
            </div>
          </ListItemStyled>
        </ListItem>
        <ListItem button>
          <ListIconStyled>
            {" "}
            <img src={QuemSomosIcon} alt="" />
          </ListIconStyled>
          <ListItemStyled>
            <div className="buttonContact">
              <Link style={{ textDecoration: "none", color: "#000000" }} to="/quemsomos">
                {" "}
                Quem Somos{" "}
              </Link>
            </div>
          </ListItemStyled>
        </ListItem>
        <ListItem button>
          <ListIconStyled>
            {" "}
            <img src={ProdutoIcon} alt="" />
          </ListIconStyled>
          <ListItemStyled>
            <div className="buttonContact">
              <Link style={{ textDecoration: "none", color: "#000000" }} to="/produtos">
                {" "}
                Produtos{" "}
              </Link>
            </div>
          </ListItemStyled>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Container>
      <div className="menu">
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              {" "}
              <MenuIcon size="40px" />{" "}
            </Button>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      <div className="efeitoSombraWhite" />
      <div className="efeitoSombra" />
      <div className="widthFixed">
        <Header>
          <ButtonAnimated>
            <span />
            <span />
            <span />
            <span />
            <Link className="textLink" to="/quemsomos">
              {" "}
              QUEM SOMOS{" "}
            </Link>
          </ButtonAnimated>
          <ButtonAnimated>
            <span />
            <span />
            <span />
            <span />
            <Link className="textLink" to="/produtos">
              {" "}
              PRODUTO{" "}
            </Link>
          </ButtonAnimated>
          <ButtonAnimated>
            <span />
            <span />
            <span />
            <span />
            <Link className="textLink" to="/contato">
              {" "}
              CONTATO{" "}
            </Link>
          </ButtonAnimated>
        </Header>
        <ButtonOrcamento>
          <img src={WhatsAppIcon} alt="" />
          <a href="https://api.whatsapp.com/send?phone=5511940570686" style={{ textDecoration: "none", color: "#00A859" }}>
            SOLICITE SEU ORÇAMENTO
          </a>
        </ButtonOrcamento>
      </div>
    </Container>
  );
}

export default BlocoUm;
