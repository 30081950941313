import React from 'react'

import { Container, Titulo, BoxText } from './styles'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'

import Logo from '../../assets/logo.png'

export default function QuemSomos() {
    return (
        <Container>
            <Header />
            <Container>
                <div className="Content">
                    <Titulo>
                        QUEM SOMOS
                        <div className='Border' />
                    </Titulo>
                    <BoxText marginT='110px' >
                        Com experiência no ramo de fabricação e vendas de escadas pré-moldadas, consumado pela qualidade e excelência temos ao longo de 30 anos o prazer de colocar no mercado "Escadas Fernanda".
                        <br />
                        Um novo conceito que nasce de uma proposta inovadora para oferecer um trabalho especializado que atenda a sua necessidade .
                        <br />
                        <br /><i>"Escadas Fernanda, um novo conceito para atender o melhor projeto "</i>
                    </BoxText>
                    <div className="box-img">
                        <img src={Logo} alt="" />
                    </div>

                </div>
            </Container>
            <Footer />
        </Container>
    )
}