import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    
    background: #EFE7D1;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-around;

    flex-direction: column;
    
    .widthFixed {
    display: none;
    }

    .carousel-div {
        width: 100%;

    }

    @media(min-width: 600px) {
      .carousel-div {

          margin-top: 170px;
          transition: 1s;
      }

      @media(min-width: 900px) {
        
        .widthFixed {
            margin-top: 100px;

        }

    }
    
    }


    @media(min-width: 900px) {



      .widthFixed {
        width: 100%;
        max-width: 1440px;

        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-around;

      }
    
      .carousel-div {
          display: none;
      }



    }
`

export const Titulo = styled.h1`
    font-family: Poppins;
    font-weight: 600;
    font-size: 3em;
    text-align: center;
    color: #3A3A3A;

    @media(min-width: 600px) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

    }
`

export const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px;
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 611px;

    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    cursor: pointer;   
    background: #ebf5f6;

    &:hover .box {
        transform: translateY(-50px);
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4);
    }

    .box {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        background: #ebf5fc;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
    } 

    .content {
        padding: 20px;
        text-align: center;

        h2 {
            position: absolute;
            top: -10px;
            right: 30px;
            font-size: 8em;
            color: rgba(0, 0, 0, 0.02);
            transition: 0.5s;
            pointer-events: none;
        }
    }


    .content img {
        display: block;
        width: 100%;
        border-radius: 5px;

    }

    @media(min-width: 800px) {
        
        height: 611px;


    }

    @media(min-width: 900px) {
        
        height: 411px;
        transition: 1s;

    }

    @media(min-width: 1000px) {
        
        height: 511px;
        transition: 1s;

    }

    @media(min-width: 1169px) {
        
        height: 611px;
        transition: 1s;

    }

`

export const Button = styled.button`
    font-family: Poppins;
    font-size: 42px;
    font-weight: 700;
    text-align: center;

    color: #DE6A1E;

    width: 100%;
    max-width: 651px;
    height: 95px;
    background: #F7D47A;
    
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);

    

    margin-top: 20px;
    margin-bottom: 20px;

    transition: 1s;

    @media(min-width: 600px) {
  


    }
    
`