import React from 'react'

import { Container, Image, Frase, ButtonQuemSomos } from './styles'

import { Link } from 'react-router-dom'

function BlocoQuatro() {


  return (
    <Container>
      <Image />
      <Frase>
        Conheça e faça parte dessa história !
      </Frase>
      <ButtonQuemSomos >
        <Link style={{ textDecoration: 'none', color: '#DE6A1E' }} to="/quemsomos">
          QUEM SOMOS
        </Link>
      </ButtonQuemSomos>
    </Container>
  );
}

export default BlocoQuatro;
