import React from "react";

import { Container, Frase } from "./styles";

import PaymentMethods from "../../../assets/payment-methods.png";

function BlocoDois() {
  return (
    <div>
      <Container>
        <h1>Facilidade de Pagamento</h1>
        <span>
          Parcelamos em até <strong>12x</strong> em todos os cartões para você realizar seu projeto sem preocupações!
        </span>
        <img src={PaymentMethods} />
      </Container>
    </div>
  );
}

export default BlocoDois;
