import React from "react";

import { Container, Content, Map, Form, ContentMobile, ContainerNumber } from "./styles";

import Email from "../../assets/email_page-contact.png";
import Marked from "../../assets/marked_page-contact.png";
import Clock from "../../assets/clock_page-contact.png";

import WhatsAppIcon from "../../assets/whatsappIconTwo.png";

import "./styles";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function Contato() {
  return (
    <Container>
      <Header />
      <Content>
        <Map>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.69011662193!2d-46.833618684546984!3d-23.54364548469165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf01fac6c8dbc3%3A0x33d550598e6f3182!2sEscadas%20Fernanda%20Ltda!5e0!3m2!1sen!2sbr!4v1649285444954!5m2!1sen!2sbr"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="iframe"
          />
        </Map>
        <Form>
          <h1> ENTRE EM CONTATO CONOSCO ! </h1>
          <ContentMobile>
            <div className="wrapper-mobile">
              <div className="email-box">
                <img src={Email} alt="Email" />
                <a href="mailto:escadasfernanda@gmail.com;atendimento@escadasfernanda.com.br"> Enviar E-mail !</a>
              </div>
              <div className="information">
                <div className="address">
                  <img src={Marked} alt="Marked" />
                  <p>
                    <strong> Fábrica Carapicuíba </strong>
                    <br />
                    Rua Agudenses, Vila Margarida, 490 <br />
                    CEP: 06331-160
                    <br />
                    <a href="https://goo.gl/maps/oifMPc5LUheFmDNu7"> Veja no mapa </a>
                  </p>
                </div>
                <div className="number-contact">
                  <img src={WhatsAppIcon} alt="WhatsAppIcon" />
                  <ContainerNumber>
                    <a href="https://api.whatsapp.com/send?phone=5511940570686">
                      Fábrica Carapicuíba:
                      <br />
                    </a>
                    <p> 11 94057-0686 </p>
                  </ContainerNumber>

                  <ContainerNumber>
                    <a href="https://api.whatsapp.com/send?phone=5511910925652">
                      Especialista em projetos <br />
                      Técnico Paulo :
                      <br />
                    </a>
                    <p> 11 91092-5652 </p>
                  </ContainerNumber>
                </div>
                <div className="hour-contact">
                  <img src={Clock} alt="Relogio" />
                  <strong> Atendimento </strong>
                  <p> 08:00 as 17:00 </p>
                </div>
              </div>
            </div>
          </ContentMobile>
        </Form>
      </Content>

      <Footer />
    </Container>
  );
}
