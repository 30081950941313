
import React from 'react'

import { Container, Titulo, Button, Card } from './styles'

import ModeloCaracol from '../../../pages/Produtos/assets/Caracol/caracolFive.jpeg'
import ModeloU from '../../../pages/Produtos/assets/U/modUcomViga.jpeg'
import ModeloReta from '../../../pages/Produtos/assets/Reta/retaComViga.jpeg'

import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { Link } from 'react-router-dom'

function BlocoCinco() {


  return (


    <Container>
      <Titulo>
        Nossos Produtos
      </Titulo>
      <div className='carousel-div'>
        <Carousel plugins={[
          'infinite',
          {
            resolve: autoplayPlugin,
            options: {
              interval: 2000,
            }
          },
        ]}
          animationSpeed={1000}>
          <Card>
            <div className='box'>
              <div className='content'>
                <img src={ModeloCaracol} alt="" />
              </div>
            </div>
          </Card>
          <Card>
            <div className='box'>
              <div className='content'>
                <img src={ModeloU} alt="" />
              </div>
            </div>
          </Card>
          <Card>
            <div className='box'>
              <div className='content'>
                <img src={ModeloReta} alt="" />
              </div>
            </div>
          </Card>

        </Carousel>

      </div>


      <div className="widthFixed">
        <Card>
          <div className='box'>
            <div className='content'>
              <img src={ModeloCaracol} alt="" />
            </div>
          </div>
        </Card>
        <Card>
          <div className='box'>
            <div className='content'>
              <img src={ModeloU} alt="" />
            </div>
          </div>
        </Card>
        <Card>
          <div className='box'>
            <div className='content'>
              <img src={ModeloReta} alt="" />
            </div>
          </div>
        </Card>

      </div>

      <Button>
        <Link style={{ textDecoration: 'none', color: '#DE6A1E' }} to="/produtos">
          CONHECER
        </Link>
      </Button>
    </Container>
  );
}

export default BlocoCinco;
