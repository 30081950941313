import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    background-color: rgba(74, 64, 98, 0.5) ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    
    .widthFixed {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

    }
    position: relative;

    @media(min-width: 600px) {

        height: 884px;

    .widthFixed {
      flex-direction: row;
        
    }

    }
`

export const Card = styled.div`
    width: 100%;
    max-width: 517px;
    height: 654px;
    border-radius: 15px;
    background: #c4c4c4;
    margin: 20px;
    display: flex; 
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);

    .map {
        width: 100%;
        height: 487px;
        background-color: #ffffff;
        border-radius: 15px;
    }

    .information {
        margin-bottom: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
    }

    iframe {
        width: 100%;
        border: none;
        border-radius: 15px;

    }
`

export const Titulo = styled.h1`
    font-family: Poppins;
    font-weight: 500;
    font-size: 72px;
    position: absolute;
    top: -105px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #3A3A3A;
`

export const Button = styled.button`
  width: 100%;
  max-width: 390px;
  height: 93px;
  background: #E1E1E1;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  border: none;
  margin-bottom: 45px;
  font-size: 20px;
  font-family: Poppins;
  color: #00A859;
  font-weight: 500;

  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
                inset -5px -5px 5px rgba(255, 255, 255, 0.05),
                5px 5px 5px rgba(0, 0, 0, 0.5),
                -5px -5px 5px rgba(255, 255, 255, 0.05);


    transition: 1s;

    :hover {
        transform: scale(1.1);
        z-index: 2;
    }
`