import React from "react";

import { Container, Card, Button } from "./styles";

function BlocoSete() {
  return (
    <Container>
      <div className="widthFixed">
        <Card>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.69011662193!2d-46.833618684546984!3d-23.54364548469165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf01fac6c8dbc3%3A0x33d550598e6f3182!2sEscadas%20Fernanda%20Ltda!5e0!3m2!1sen!2sbr!4v1649285444954!5m2!1sen!2sbr"
              title="mapa"
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
            />
          </div>

          <div className="information">
            <strong> Fábrica Carapicuíba </strong>
            <br />
            Rua Agudenses, Vila Margarida, 490 <br />
            CEP: 06331-160
            <br />
          </div>
        </Card>
      </div>
      <Button>
        <a href="https://api.whatsapp.com/send?phone=5511978977463" style={{ textDecoration: "none", color: "#00A859" }}>
          {" "}
          FAÇA SEU ORÇAMENTO{" "}
        </a>
      </Button>
    </Container>
  );
}

export default BlocoSete;
