import React from 'react'

import { Container, Card } from './styles'

import EscadaLOne from '../../assets/L/single-L-1.jpg'
import EscadaLTwo from '../../assets/L/single-L-2.jpg'
import EscadaLThree from '../../assets/L/single-L-3.jpg'

import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


export default function ModeloReta() {

  return (
    <Container>
      <Carousel plugins={[
        'arrows',
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 2
          }
        },
      ]}
        breakpoints={{
          900: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2
                }
              },
            ]
          }
        }}>
        <Card>
          <img src={EscadaLOne} alt="" />
        </Card>
        <Card>
          <img src={EscadaLTwo} alt="" />
        </Card>
        <Card>
          <img src={EscadaLThree} alt="" />
        </Card>

      </Carousel>
    </Container>
  )
}

