import React from 'react'
import BlocoUm from './BlocoUm'
import BlocoDois from './BlocoDois'
import BlocoTrês from './BlocoTrês'
import BlocoQuatro from './BlocoQuatro'
import BlocoCinco from './BlocoCinco'
import BlocoSeis from './BlocoSeis'
import Footer from '../../Components/Footer'

import { Divisoria } from './styles'


function Container() {


  return (
    <div>
      <BlocoUm />
      <BlocoDois />
      <Divisoria />
      <BlocoTrês />
      <Divisoria />
      <BlocoQuatro />
      <Divisoria />
      <BlocoCinco />
      <Divisoria />
      <BlocoSeis />
      <Divisoria />
      <Footer />
    </div>
  );
}

export default Container;
