import styled from 'styled-components'

export const Divisoria = styled.div`
    width: 100%;
    height: 50px;
    background: #ffffff;
`

export const DivisoriaSM = styled.div`
    width: 100%;
    height: 50px;
    background: #ffffff;
`