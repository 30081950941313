import React from 'react'

import { Container, Card } from './styles'


import ModeloUVigas from '../../assets/U/modUcomViga.jpeg'
import ModeloUVigasEsp from '../../assets/U/modUcomVigaEspecial.jpeg'

import ModUVigasCat1 from '../../assets/U/single-reta-3.jpg'


import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


export default function ModeloCaracol() {

  return (
    <Container>
      <Carousel plugins={[
        'arrows',
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 2
          }
        },
      ]}
        breakpoints={{
          900: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2
                }
              },
            ]
          }
        }}>
        <Card>
          <img src={ModeloUVigas} alt="" />
        </Card>
        <Card>
          <img src={ModeloUVigasEsp} alt="" />
        </Card>
        <Card>
          <img src={ModUVigasCat1} alt="" />
        </Card>

      </Carousel>
    </Container>
  )
}

