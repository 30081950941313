import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";



import BackgroundImage from '../../assets/backgroundHeader.png'
import { HiMenu } from "react-icons/hi"


export const StyledTabs = withStyles({

})

export const Container = styled.div`
    width: 100%;
    height: 284px;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 

    .efeitoSombra {
        width: 100%;
        height: 284px;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        z-index: 1;

    }

    .menu button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }

    .others-page {
        display: none;
    }
    

    @media(min-width: 800px) {
        .menu {
            display: none;
        }

        .others-page {
            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            left: 40px;
            top: 20px;

            z-index: 2;


        }

        .others-page strong {
            font-family: Poppins; 
            color: #ffffff;
            padding: 20px;
            
        }
    }

    

`

export const MenuIcon = styled(HiMenu)`
    color: #ffffff;
`

export const ListItemStyled = styled(ListItemText)`
.buttonContact {
        font-family: Poppins;
        text-align:center;
    }
`

export const ListIconStyled = styled(ListItemIcon)`

    img {
        width: 42px;
    }
`