import styled from 'styled-components'

import { SiFacebook, SiInstagram } from "react-icons/si"
import { HiHome } from "react-icons/hi";


export const Container = styled.div`
    width: 100%;
    height: 237px;
    background: #161F29;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    .logo img {
        width: 100%;
        max-width: 200px;
        display: flex;
        align-items: center;

    }

    .social {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 45px;
        max-width: 400px;
        height: 100px;
    }

    .maxWidth {
        width: 100%;
        max-width: 1440px;

        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    @media (min-width: 450px) {
        margin-right: 30px;

        .social {

        margin-top: 75px;


    }
    }
`

export const IconFacebook = styled(SiFacebook)`
    color: #FFFFFF;

    @media (min-width: 450px) {
        margin-right: 30px;
        width: 100px;

    }
    `

export const IconInstagram = styled(SiInstagram)`
    width: 100px;
    color: #FFFFFF;
    
    @media (min-width: 450px) {
        margin-right: 30px;
    }
    `

export const IconHome = styled(HiHome)`
    color: #FFFFFF;

    @media (min-width: 450px) {
        width: 100px;
        margin-right: 30px;
    }
    `