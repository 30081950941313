import React from "react";

import { Container, IconFacebook, IconInstagram, IconHome } from "./styles";

import { Link } from "react-router-dom";

import Logo from "../../assets/logo.png";

function Footer() {
  return (
    <Container>
      <div className="maxWidth">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="social">
          <div>
            <Link to="/">
              <IconHome size={35} />
            </Link>
            <a href="https://www.instagram.com/escadasfernanda/">
              <IconInstagram size={35} />
            </a>
            <Link to="/">
              <IconFacebook size={35} />
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
